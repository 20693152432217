<!-- 新增子表记录 -->
<template>
  <div class='CustomFormFormSubmit'>
    <form-panel
      ref="formPanel"
      :label-position="formConfig.labelPosition"
      :label-width="formConfig.labelWidth + 'px'"
      :form="formData"
    >
      <template #headerSlot>
        <v-button v-if="showBack" text="返回"  @click="returnClick" />
      </template>
      <col2-detail v-if="formShow">
        <col2-block>
          <div v-for="(item, index) in controlsItem.tableColumns" :key="index">
            <custom-form-item v-if="['area', 'org', 'community', 'communityroom'].includes(item.type)"
              :models.sync="formData"
              :item="item"
              ref="selectedChangeValue"
            />
            <custom-form-item v-else
              :models.sync="formData"
              :item="item"
            />
          </div>
        </col2-block>
      </col2-detail>
      <template #footerSlot>
        <v-button v-if="showBack" type="success" text="保存"  @click="saveClick" />
      </template>
      <template #saveAfterSlot v-if="!edit">
        <v-button v-if="showBack" type="success" text="保存并继续新增"  @click="saveAndAddClick" />
      </template>
    </form-panel>
  </div>
</template>

<script>
import { Col2Detail, Col2Block } from 'components/bussiness'
import CustomFormItem from './CustomFormItem'
import moment from 'moment'

export default {
  name: 'CustomFormFormSubmit',
  components: {
    Col2Detail,
    Col2Block,
    CustomFormItem
  },
  data () {
    return {
      formShow: false,
      isChange: false,
      formName: '',
      formConfig: {},
      tableList: [],
      tableIndex: -1,
      edit: false,
      modelMap: {},
      formData: {},
      controlList: [],
      controlsItem: ''
    }
  },
  mounted () {
    this.handleData()
  },
  computed: {
    showBack () {
      const { noBack } = this.$route.query
      return noBack === undefined
    },
  },
  methods: {
    // 处理数据
    handleData () {
      const {formName, formConfig, tableList, item, model, tableIndex} = this.$route.query
      // 处理面包屑显示
      if (formName && formName.length > 0) {
        this.formName = formName
      }
      this.tableList = tableList
      this.tableIndex = tableIndex
      this.formConfig = formConfig
      this.controlsItem = item
      this.initFormData(item.tableColumns)
      if (model !== undefined) {
        this.edit = true
        this.update(model)
      }
      this.watchFormData()
      this.resetBreadcrumb(this.edit)
      this.formShow = true
    },
    // 修改头上显示的路劲
    resetBreadcrumb (edit) {
      const breadcrumb = edit ? `表单记录 —《${this.formName}》 > 编辑记录 > 编辑${this.controlsItem.name}` : `表单记录 —《${this.formName}》 > 新增记录 > 新增${this.controlsItem.name}`
      this.$setBreadcrumb(breadcrumb)
    },
    // 返回按钮
    async returnClick () {
      const isChange = this.isChange
      if (isChange) {
        const result = await this.$confirm('未保存填写是否返回')
        if (!result) {
          return
        }
      }
      this.$router.go(-1)
    },
    initFormData (list) {
      if (Array.isArray(list) && list.length > 0) {
        list.forEach(item => {
          if (item.type === 'grid') {
            item.columns.forEach(column => {
              this.initFormData(column.list)
            })
          } else {
            if (['radio', 'checkbox'].includes(item.type)) {
              const options = item.options.options
              if (Array.isArray(options) && options.length > 0) {
                const modelMap = {}
                options.forEach(option => {
                  if (option['label'] === undefined) { // 兼容历史数据：option只有value属性
                    option['label'] = option.value
                  }
                  modelMap[option.value] = option['label']
                })
                this.modelMap[item.model] = modelMap
              }
            } else if (['select'].includes(item.type)) {
              const options = item.options.options
              if (Array.isArray(options) && options.length > 0) {
                const modelMap = {}
                options.forEach(option => {
                  if (option['label'] === undefined) { // 兼容历史数据：option只有value属性
                    option['text'] = option.value
                  } else {
                    option['text'] = option['label']
                  }
                  modelMap[option.value] = option['text']
                })
                this.modelMap[item.model] = modelMap
              }
              if (item.options.defaultValue === '') {
                item.options.defaultValue = undefined
              }
            } else if (['area'].includes(item.type)) {
              item.options.defaultValue = []
            } else if (['imgupload'].includes(item.type)) {
              if (item.options.length === 1) {
                item.options.defaultValue = ''
              }
            } else if (['usersex'].includes(item.type)) {
              const options = item.options.options
              if (Array.isArray(options) && options.length > 0) {
                const modelMap = {}
                options.forEach(option => {
                  modelMap[option.value] = option.label
                })
                this.modelMap[item.model] = modelMap
              }
            } else if (item.type === 'time') {
              item.options.defaultValue = moment().format('HH:mm')
            } else if (item.type === 'date') {
              const formatType = item.options.type
              if (formatType === 'date') {
                item.options.defaultValue = moment().format('YYYY-MM-DD')
              } else if (formatType === 'datetime') {
                item.options.defaultValue = moment().format('YYYY-MM-DD HH:mm')
              }
            }
            if (item.options.dataBind) {
              this.formData[item.model] = item.options.defaultValue
              this.controlList.push(item)
            }
          }
        })
      }
    },
    getFileName (src) {
      return src.substr(src.lastIndexOf('\/') + 1)
    },
    isImage (src) {
      const imgType = ['gif', 'jpeg', 'jpg', 'bmp', 'png']
      if (RegExp('\.(' + imgType.join('|') + ')$', 'i').test(src)) {
        return true
      }
      return false
    },
    update (data) {
      Object.keys(this.formData).forEach(key => {
        const formListItem = this.controlList.find((item) => {
          return item.model === key
        })
        if (formListItem) {
          const listItemType = formListItem.type
          if (data[key] !== undefined) {
            if (['checkbox'].includes(listItemType)) {
              const value = data[key] === '' ? [] : data[key].split(',')
              this.formData[key] = value
            } else if (['usersex'].includes(listItemType)) {
              const value = String(data[key])
              this.formData[key] = value
            } else if (['select'].includes(listItemType)) {
              const value = data[key] === '' ? undefined : data[key]
              this.formData[key] = value
            } else if (['area'].includes(listItemType)) {
              // 省市区的特殊处理
              const value = data[key] === '' ? undefined : data[key]
              if (typeof value === 'string') {
                const result = JSON.parse(value)
                this.formData[key] = result
              } else {
                this.formData[key] = value
              }
            } else if (['switch'].includes(listItemType)) {
              const value = data[key] === 1
              this.formData[key] = value
            } else if (['imgupload', 'fileupload'].includes(listItemType)) {
              let value = []
              try {
                value = JSON.parse(data[key])
              } catch {
                value = data[key]
              }
              if (['fileupload'].includes(listItemType)) {
                if (Array.isArray(value) && value.length) {
                  const tempValue = []
                  value.forEach(url => {
                    tempValue.push({
                      name: this.getFileName(url),
                      isOther: !this.isImage(url),
                      url
                    })
                  })
                  value = tempValue
                }
              }
              if (typeof this.formData[key] === 'string') {
                this.formData[key] = value && value.length > 0 ? value[0] : ''
              } else {
                this.formData[key] = value
              }
            } else {
              this.formData[key] = data[key]
            }
          }
        }
      })
      this.watchFormData()
    },
    submitBefore (data) {
      const formDataMap = {}
      Object.keys(data).forEach(key => {
        const formListItem = this.controlList.find((item) => {
          return item.model === key
        })
        if (formListItem) {
          const listItemType = formListItem.type
          if (['checkbox'].includes(listItemType)) {
            const checkboxDisplay = []
            if (data[key] && data[key].length > 0) {
              data[key].forEach(item => {
                checkboxDisplay.push(this.modelMap[key][item])
              })
            }
            formDataMap[key + '_display'] = checkboxDisplay.join(',')
            formDataMap[key] = data[key].join(',')
          } else if (['radio', 'select', 'usersex'].includes(listItemType)) {
            formDataMap[key + '_display'] = this.modelMap[key][data[key]]
            formDataMap[key] = data[key]
          } else if (['table'].includes(listItemType)) {
            formDataMap[key] = data[key]
          } else if (['switch'].includes(listItemType)) {
            formDataMap[key + '_display'] = data[key] ? '是' : '否'
            formDataMap[key] = data[key] ? 1 : 0
          } else if (['area','community', 'org', 'communityroom'].includes(listItemType)) {
            let chil = this.$refs['selectedChangeValue']
            if (Array.isArray(chil) && chil.length > 0) {
              chil.forEach(item => {
                if (item.item.model == key) {
                  formDataMap[key + '_display'] = item.selectedChangeValue
                }
              })
            }
            formDataMap[key] = data[key]
          } else if (['imgupload'].includes(listItemType)) {
            if (typeof data[key] === 'string') {
              data[key] = [data[key]]
            }
            formDataMap[key] = data[key]
          } else if (['fileupload'].includes(listItemType)) {
            const fileUrlList = []
            const fileObjList = data[key]
            if (Array.isArray(fileObjList) && fileObjList.length) {
              fileObjList.forEach(fileObjItem => {
                fileUrlList.push(fileObjItem.url)
              })
            }
            formDataMap[key] = fileUrlList
          } else {
            formDataMap[key] = data[key]
          }
        }
      })
      const controlkey = this.controlsItem.model
      if (this.edit) {
        // 编辑
        this.tableList[this.tableIndex] = formDataMap
      } else {
        // 新增
        this.tableList.push(formDataMap)
      }
      this.$store.state.customTableStore.tableData[controlkey] = this.tableList
    },
    // 保存按钮
    saveClick () {
      let result = this.$refs.formPanel.validate()
      if (!result) {
        return
      }
      this.submitBefore(this.formData)
      this.$router.go(-1)
    },
    // 保存并继续新增
    saveAndAddClick () {
      let result = this.$refs.formPanel.validate()
      if (!result) {
        return
      }
      this.submitBefore(this.formData)
      this.$refs.formPanel.resetForm()
      this.$nextTick(() => {
        this.$refs.formPanel.clearValidate()
      })
    },
    watchFormData () {
      const _this = this
      const unwatch = _this.$watch('formData', (newValue) => {
        _this.isChange = true
        unwatch()
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.CustomFormFormSubmit {
  .CustomFormItem {
    ::v-deep {
      .custom-form-item-container {
        width: 100%;
        display: inline-block;
      }
      .custom-width {
        .v-input-container {
          width: 100%;
          .el-input {
            width: 100% !important;
          }
        }
        .v-textarea-container {
          width: 100%;
          .el-textarea {
            width: 100% !important;
          }
        }
        .v-input-number-container {
          width: 100%;
          .el-input-number {
            width: 100% !important;
          }
        }
        .v-timepicker-contianer {
          width: 100%;
          .mx-datepicker {
            width: 100% !important;
          }
        }
        .v-datepicker-container {
          width: 100%;
          .mx-datepicker {
            width: 100% !important;
          }
        }
        .v-select-container {
          width: 100%;
          .el-select {
            width: 100% !important;
          }
        }
        .v-select2-container {
          .select2Parent {
            width: 100% !important;
          }
        }
        .v-cascader-wrapper {
          width: 100%;
          .el-cascader {
            width: 100% !important;
          }
        }
      }
    }
  }
}
</style>
