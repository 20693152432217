var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "CustomFormFormSubmit" },
    [
      _c(
        "form-panel",
        {
          ref: "formPanel",
          attrs: {
            "label-position": _vm.formConfig.labelPosition,
            "label-width": _vm.formConfig.labelWidth + "px",
            form: _vm.formData,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "headerSlot",
                fn: function () {
                  return [
                    _vm.showBack
                      ? _c("v-button", {
                          attrs: { text: "返回" },
                          on: { click: _vm.returnClick },
                        })
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
              {
                key: "footerSlot",
                fn: function () {
                  return [
                    _vm.showBack
                      ? _c("v-button", {
                          attrs: { type: "success", text: "保存" },
                          on: { click: _vm.saveClick },
                        })
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
              !_vm.edit
                ? {
                    key: "saveAfterSlot",
                    fn: function () {
                      return [
                        _vm.showBack
                          ? _c("v-button", {
                              attrs: {
                                type: "success",
                                text: "保存并继续新增",
                              },
                              on: { click: _vm.saveAndAddClick },
                            })
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _vm.formShow
            ? _c(
                "col2-detail",
                [
                  _c(
                    "col2-block",
                    _vm._l(
                      _vm.controlsItem.tableColumns,
                      function (item, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            [
                              "area",
                              "org",
                              "community",
                              "communityroom",
                            ].includes(item.type)
                              ? _c("custom-form-item", {
                                  ref: "selectedChangeValue",
                                  refInFor: true,
                                  attrs: { models: _vm.formData, item: item },
                                  on: {
                                    "update:models": function ($event) {
                                      _vm.formData = $event
                                    },
                                  },
                                })
                              : _c("custom-form-item", {
                                  attrs: { models: _vm.formData, item: item },
                                  on: {
                                    "update:models": function ($event) {
                                      _vm.formData = $event
                                    },
                                  },
                                }),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }